<template>
  <v-card flat class="mt-5">
        <v-img
          class="team-light-bg"
          :class="{'team-dark-bg': theme}"
          :src="theme ? `${$s3path}/images/background-team-dark.png` : `${$s3path}/images/background-team-light.svg`"
        >
          <v-container>
              <v-row class="mt-15">
                <v-col></v-col>
                <v-col cols=11>
                  <v-card flat class="transparent white--text">
                    <v-card-title class="mt-5">
                      The Founding Team
                    </v-card-title>
                    <v-card-text class="white--text mt-">
                      <span>haq.ai is an Eigensolver project, which is an organization founded by Adam Fattal and the following legends:</span>
                    </v-card-text>

                    <v-row>
                      <v-col>
                        <v-card class="rounded-lg d-flex">
                          <div class="ma-2">
                            <v-img width="188px" height="188px" class="rounded-lg" src="https://haqai.s3.us-east-2.amazonaws.com/founderimage/62197476_2427255327325427_3813529327866216448_n.png" />
                          </div>
                          <div>
                            <v-card-title>
                              <div >
                                <div class="">Filip Rękawek</div>
                                <span class="blue--text text-secondary"> Bio</span>
                              </div>
                            </v-card-title>
                            <v-card-text>
                              <div>
                                Undergraduate of Math and Physics at University of Warsaw
                              </div>
                            </v-card-text>
                          </div>
                          
                        </v-card>
                      </v-col>
                      <v-col>
                        <v-card class="rounded-lg d-flex">
                          <div class="ma-2">
                            <v-img width="188px" height="188px" class="rounded-lg" src="https://haqai.s3.us-east-2.amazonaws.com/founderimage/f7a5b108.png" />
                          </div>
                          <div>
                            <v-card-title>
                              <div >
                                <div class="">Minh Pham</div>
                                <span class="blue--text text-secondary"> Bio</span>
                              </div>
                            </v-card-title>
                            <v-card-text>
                              <div>
                                Undergraduate of Math at UChicago and Qiskit Advocate
                              </div>
                            </v-card-text>
                          </div>
                          
                        </v-card>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <v-card class="rounded-lg d-flex">
                          <div class="ma-2">
                            <v-img width="188px" height="188px" class="rounded-lg" src="https://haqai.s3.us-east-2.amazonaws.com/founderimage/image0.jpg" />
                          </div>
                          <div>
                            <v-card-title>
                              <div >
                                <div class="">Emilio Pelaez</div>
                                <span class="blue--text text-secondary">Bio</span>
                              </div>
                            </v-card-title>
                            <v-card-text>
                              <div>
                                Qiskit advocate
                              </div>
                            </v-card-text>
                          </div>
                          
                        </v-card>
                      </v-col>
                      <v-col>
                        <v-card class="rounded-lg d-flex">
                          <div class="ma-2">
                            <v-img width="188px" height="188px" class="rounded-lg" src="https://haqai.s3.us-east-2.amazonaws.com/founderimage/6d7bbe38.jpg" />
                          </div>
                          <div>
                            <v-card-title>
                              <div >
                                <div class="">Mehil Agarwal</div>
                                <span class="blue--text text-secondary"> Bio</span>
                              </div>
                            </v-card-title>
                            <v-card-text>
                              <div>
                                PhD Student at Univeristy of Portland and Qiskit Advocate
                              </div>
                            </v-card-text>
                          </div>
                          
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
                <v-col></v-col>
              </v-row>
          </v-container>
        </v-img>
       
  </v-card>
</template>

<script>
import colorMxn from '@/mixins/color_mixin';

export default {
  name: 'team',
  mixins: [colorMxn],
  data() {
    return {
    }
  }
}
</script>
